import { useEffect } from 'react'
import { useTabState } from '~/components/tabBarProvider'
import { getLayout } from '~/components/tabLayout'
import { TabItems } from '~/types'

const Page = () => {
  const tabBarState = useTabState()

  useEffect(() => {
    tabBarState.setActiveTab(TabItems.HOME)
    // eslint-disable-next-line
  }, [])

  return null
}

export default Page

Page.getLayout = getLayout
